export type LinkToType = 'self' | 'talent' | 'guide' | 'outside'

/**
 * talent, guideのアプリケーション差異によって、リンクの表現を変化させる
 *
 * self -> 下記定義に沿って分岐
 * talent内からtalentへのリンク -> relative path
 * talent内からguideへのリンク -> relative path
 * guide内からtalentへのリンク -> full path ex: https://zcareer.com/login
 * guide内からguideへのリンク -> /guideを除いたレラティブパス
 * 外部へのリンク -> full path ex: https://google.com
 */
export const linkSwitcher = (link: string, linkTo: LinkToType) => {
  const appName = process.env.NEXT_PUBLIC_APP_NAME

  // guide内からtalentへのリンク
  if (appName === 'guide' && linkTo === 'talent')
    return process.env.NEXT_PUBLIC_TALENT_APP_URL + link
  // guide内からguideへのリンク
  if ((appName === 'guide' && linkTo === 'guide') || (appName === 'guide' && linkTo === 'self'))
    return link.replace('/guide', '')
  // 外部へのリンク
  if (linkTo === 'outside') return link // そのままでfull pathで渡ってきているはず

  return link
}
