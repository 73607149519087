import { atom } from 'jotai'

import { particularConditions } from '@/const/particularConditions'
import { prefectures } from '@/const/prefectures'
import { LargeMasterListItem, MediumMasterListItem } from '@/oas/public/api'

export type Masters = {
  prefectures: MediumMasterListItem[]
  jobCategories: MediumMasterListItem[]
  particularConditions: MediumMasterListItem[]
}

/**
 * ファイル由来のmastersをまとめて取り出す
 */
export const callMasters = () => {
  return {
    prefectures: prefectures,
    particularConditions: particularConditions,
  }
}

export const masterAtom = atom<LargeMasterListItem[]>([])
masterAtom.debugLabel = 'master'
