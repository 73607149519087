import { useAtom } from 'jotai'
import useSWR from 'swr'

import { getPublicApiUrl } from '@/api/apiHostNameSwitcher'
import { masterAtom } from '@/globalState/master'
import { LargeMasterListItem } from '@/oas/public/api'

const fetcher = (url: string) => fetch(url).then((res) => res.json())
export const useMaster = (category: string[]) => {
  const [master, setMaster] = useAtom(masterAtom)

  const masterCategories = master.map((v) => v.category)
  const newCategory = category.filter((v) => !masterCategories.includes(v))

  const categoryQuery = newCategory.map((v) => `category%5B%5D=${v}`).join('&')

  useSWR(newCategory.length > 0 ? `${getPublicApiUrl()}/master?${categoryQuery}` : null, fetcher, {
    onSuccess: (data: LargeMasterListItem[]) => {
      setMaster((prev) => [...prev, ...data])
    },
  })
}
