/* eslint-disable no-console */
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import useSWR from 'swr'

import { fetchGlobalAlertsApi } from '@/api/authApi'
import { authMeAtom } from '@/globalState/auth'

export const useGlobalAlerts = () => {
  const me = useAtomValue(authMeAtom)
  const { data, isLoading, mutate } = useSWR(
    me ? '/api/talent/auth/fetch_global_alerts' : null,
    () =>
      fetchGlobalAlertsApi()
        .then((res) => res.data)
        .catch((e: AxiosError) => {
          throw e
        }),
    {
      // 画面遷移ごとに再取得する設定
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
  /**
   * キャッシュしているshowLineFriendNoticeの値をAPIフェッチを介さずに更新する
   */
  const localRefetchShowLineFriendNotice = (value: boolean) => {
    mutate({ ...data, showLineFriendNotice: value }, false)
  }

  return {
    isLoadingGlobalAlerts: isLoading,
    showLineFriendNotice: data && data.showLineFriendNotice,
    mutateGlobalAlerts: mutate,
    localRefetchShowLineFriendNotice,
  }
}
