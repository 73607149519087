import { useEffect, useLayoutEffect, useState } from 'react'

export const useWindowSize = () => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [isPcView, setIsPcView] = useState(false)
  const isomorphicEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

  isomorphicEffect(() => {
    const updateSize = (): void => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
      // 今回の場合はPCの幅を640px以上としている
      setIsPcView(window.innerWidth > 639)
    }

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return { width, height, isPcView }
}
