interface Props {
  category: 'jobDetail' | 'footer'
}

export const DocumentTextIcon: React.FC<Props> = (props: Props) => {
  const { category } = props
  if (category === 'jobDetail') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.2002 3.19998C3.2002 2.31632 3.91654 1.59998 4.8002 1.59998H8.46882C8.89317 1.59998 9.30014 1.76855 9.6002 2.0686L12.3316 4.79998C12.6316 5.10003 12.8002 5.507 12.8002 5.93135V12.8C12.8002 13.6836 12.0839 14.4 11.2002 14.4H4.8002C3.91654 14.4 3.2002 13.6836 3.2002 12.8V3.19998ZM4.8002 7.99998C4.8002 7.55815 5.15837 7.19998 5.6002 7.19998H10.4002C10.842 7.19998 11.2002 7.55815 11.2002 7.99998C11.2002 8.4418 10.842 8.79998 10.4002 8.79998H5.6002C5.15837 8.79998 4.8002 8.4418 4.8002 7.99998ZM5.6002 10.4C5.15837 10.4 4.8002 10.7581 4.8002 11.2C4.8002 11.6418 5.15837 12 5.6002 12H10.4002C10.842 12 11.2002 11.6418 11.2002 11.2C11.2002 10.7581 10.842 10.4 10.4002 10.4H5.6002Z"
          fill="#4BD281"
        />
      </svg>
    )
  }

  if (category === 'footer') {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4C4 2.89543 4.89543 2 6 2H10.5858C11.1162 2 11.6249 2.21071 12 2.58579L15.4142 6C15.7893 6.37507 16 6.88378 16 7.41421V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V4ZM6 10C6 9.44772 6.44772 9 7 9H13C13.5523 9 14 9.44772 14 10C14 10.5523 13.5523 11 13 11H7C6.44772 11 6 10.5523 6 10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H7Z"
          fill="#1A202C"
        />
      </svg>
    )
  }
}
