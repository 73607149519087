import { SelectOptionOfGroup } from '@/modules/components/MultiSelect'
import { MediumMasterListItem } from '@/oas/public/api'

export const prefectures: MediumMasterListItem[] = [
  { id: 11, label: '北海道' },
  { id: 12, label: '青森県' },
  { id: 13, label: '岩手県' },
  { id: 14, label: '宮城県' },
  { id: 15, label: '秋田県' },
  { id: 16, label: '山形県' },
  { id: 17, label: '福島県' },

  { id: 21, label: '東京都' },
  { id: 22, label: '神奈川県' },
  { id: 23, label: '埼玉県' },
  { id: 24, label: '千葉県' },
  { id: 25, label: '茨城県' },
  { id: 26, label: '栃木県' },
  { id: 27, label: '群馬県' },

  { id: 31, label: '新潟県' },
  { id: 32, label: '富山県' },
  { id: 33, label: '石川県' },
  { id: 34, label: '福井県' },
  { id: 35, label: '山梨県' },
  { id: 36, label: '長野県' },
  { id: 37, label: '岐阜県' },
  { id: 38, label: '静岡県' },
  { id: 39, label: '愛知県' },

  { id: 41, label: '三重県' },
  { id: 42, label: '滋賀県' },
  { id: 43, label: '京都府' },
  { id: 44, label: '大阪府' },
  { id: 45, label: '兵庫県' },
  { id: 46, label: '奈良県' },
  { id: 47, label: '和歌山県' },

  { id: 51, label: '鳥取県' },
  { id: 52, label: '島根県' },
  { id: 53, label: '岡山県' },
  { id: 54, label: '広島県' },
  { id: 55, label: '山口県' },

  { id: 61, label: '徳島県' },
  { id: 62, label: '香川県' },
  { id: 63, label: '愛媛県' },
  { id: 64, label: '高知県' },

  { id: 71, label: '福岡県' },
  { id: 72, label: '佐賀県' },
  { id: 73, label: '長崎県' },
  { id: 74, label: '熊本県' },
  { id: 75, label: '大分県' },
  { id: 76, label: '宮崎県' },
  { id: 77, label: '鹿児島県' },
  { id: 78, label: '沖縄県' },
]
export const prefecturesLabelValue = (): { label: string; value: number }[] => {
  return prefectures.map((prefecture) => ({
    label: prefecture.label,
    value: prefecture.id,
  }))
}

export const prefecturesOfRegion: SelectOptionOfGroup[] = [
  {
    groupLabel: '北海道・東北',
    items: [
      { value: 11, label: '北海道' },
      { value: 12, label: '青森県' },
      { value: 13, label: '岩手県' },
      { value: 14, label: '宮城県' },
      { value: 15, label: '秋田県' },
      { value: 16, label: '山形県' },
      { value: 17, label: '福島県' },
    ],
  },

  {
    groupLabel: '関東',
    items: [
      { value: 21, label: '東京都' },
      { value: 22, label: '神奈川県' },
      { value: 23, label: '埼玉県' },
      { value: 24, label: '千葉県' },
      { value: 25, label: '茨城県' },
      { value: 26, label: '栃木県' },
      { value: 27, label: '群馬県' },
    ],
  },

  {
    groupLabel: '中部',
    items: [
      { value: 31, label: '新潟県' },
      { value: 32, label: '富山県' },
      { value: 33, label: '石川県' },
      { value: 34, label: '福井県' },
      { value: 35, label: '山梨県' },
      { value: 36, label: '長野県' },
      { value: 37, label: '岐阜県' },
      { value: 38, label: '静岡県' },
      { value: 39, label: '愛知県' },
    ],
  },

  {
    groupLabel: '近畿',
    items: [
      { value: 41, label: '三重県' },
      { value: 42, label: '滋賀県' },
      { value: 43, label: '京都府' },
      { value: 44, label: '大阪府' },
      { value: 45, label: '兵庫県' },
      { value: 46, label: '奈良県' },
      { value: 47, label: '和歌山県' },
    ],
  },

  {
    groupLabel: '中国',
    items: [
      { value: 51, label: '鳥取県' },
      { value: 52, label: '島根県' },
      { value: 53, label: '岡山県' },
      { value: 54, label: '広島県' },
      { value: 55, label: '山口県' },
    ],
  },

  {
    groupLabel: '四国',
    items: [
      { value: 61, label: '徳島県' },
      { value: 62, label: '香川県' },
      { value: 63, label: '愛媛県' },
      { value: 64, label: '高知県' },
    ],
  },

  {
    groupLabel: '九州・沖縄',
    items: [
      { value: 71, label: '福岡県' },
      { value: 72, label: '佐賀県' },
      { value: 73, label: '長崎県' },
      { value: 74, label: '熊本県' },
      { value: 75, label: '大分県' },
      { value: 76, label: '宮崎県' },
      { value: 77, label: '鹿児島県' },
      { value: 78, label: '沖縄県' },
    ],
  },
]
