interface Props {
  category: 'breadcrumb' | 'footer'
}

export const HomeIcon: React.FC<Props> = (props: Props) => {
  const { category } = props
  if (category === 'breadcrumb')
    return (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.42446 1.37569C6.19015 1.14137 5.81025 1.14137 5.57593 1.37569L1.37593 5.57569C1.14162 5.81 1.14162 6.1899 1.37593 6.42422C1.61025 6.65853 1.99014 6.65853 2.22446 6.42422L2.4002 6.24848V10.2C2.4002 10.5313 2.66882 10.8 3.0002 10.8H4.2002C4.53157 10.8 4.8002 10.5313 4.8002 10.2V8.99995C4.8002 8.66858 5.06882 8.39995 5.4002 8.39995H6.6002C6.93157 8.39995 7.2002 8.66858 7.2002 8.99995V10.2C7.2002 10.5313 7.46882 10.8 7.8002 10.8H9.0002C9.33157 10.8 9.6002 10.5313 9.6002 10.2V6.24848L9.77593 6.42422C10.0102 6.65853 10.3901 6.65853 10.6245 6.42422C10.8588 6.1899 10.8588 5.81 10.6245 5.57569L6.42446 1.37569Z"
          fill="#25A3DB"
        />
      </svg>
    )
  if (category === 'footer')
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.7071 2.29289C10.3166 1.90237 9.68342 1.90237 9.29289 2.29289L2.29289 9.29289C1.90237 9.68342 1.90237 10.3166 2.29289 10.7071C2.68342 11.0976 3.31658 11.0976 3.70711 10.7071L4 10.4142V17C4 17.5523 4.44772 18 5 18H7C7.55228 18 8 17.5523 8 17V15C8 14.4477 8.44772 14 9 14H11C11.5523 14 12 14.4477 12 15V17C12 17.5523 12.4477 18 13 18H15C15.5523 18 16 17.5523 16 17V10.4142L16.2929 10.7071C16.6834 11.0976 17.3166 11.0976 17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289L10.7071 2.29289Z"
          fill="#1A202C"
        />
      </svg>
    )
}
