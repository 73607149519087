import { MediumMasterListItem } from '@/oas/public/api'

export const particularConditions: MediumMasterListItem[] = [
  {
    id: 1,
    label: '土日祝休み',
  },
  {
    id: 2,
    label: '年間休日120日以上',
  },
  {
    id: 3,
    label: '月残業20時間以内',
  },
  {
    id: 4,
    label: '転勤なし',
  },
  {
    id: 5,
    label: '服装自由',
  },
  {
    id: 6,
    label: '上場企業',
  },
  {
    id: 7,
    label: '車通勤OK',
  },
  {
    id: 8,
    label: '時短勤務可',
  },
  {
    id: 9,
    label: '社員寮あり',
  },
  {
    id: 10,
    label: '正社員経験なしOK',
  },
]
