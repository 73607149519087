import React, { memo } from 'react'
import { ChevronDownIcon, ChevronRightIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Button, Center, Flex, Image as ChakraImage, SimpleGrid, Text } from '@chakra-ui/react'
import Image from 'next/image'

import { MediumMasterListItem } from '@/oas/public/api'

import AcademicCap from '../assets/icon/AcademicCap.svg'
import { BriefcaseIcon } from '../assets/icon/BriefcaseIcon'
import { DocumentTextIcon } from '../assets/icon/DocumentTextIcon'
import Heart from '../assets/icon/Heart.svg'
import { HomeIcon } from '../assets/icon/HomeIcon'
import LightBulb from '../assets/icon/LightBulb.svg'
import { LocationMarker } from '../assets/icon/LocationMarkerIcon'
import Logo from '../assets/images/logo.svg'
import Pmark from '../assets/images/pmark.png'
import { inquiryPage, operatingCompanyPage, privacyPolicyPage } from '../constant'
import { GTMClickItemName } from '../utils/gtm/types/common'

import { ChakraLink } from './ChakraLink'
import { FooterAccordionList } from './FooterAccordionList'

import style from '../styles/Footer.module.scss'

interface Props {
  jobCategories: MediumMasterListItem[]
  isPreview?: boolean
  showAgentConnectAppeal?: boolean // Why: 応募完了画面等、ACへの導線がユーザーに混乱を生む画面では当該訴求を消したい
  isLocal: boolean
  talentAppUrl?: string
  guideAppUrl?: string
}

const Footer: React.FC<Props> = memo(
  ({ jobCategories, isPreview, isLocal, talentAppUrl, guideAppUrl }) => {
    const boxStyleWithIcon = {
      width: '100%',
      height: '48px',
      p: '14px 16px 14px 12px',
    }
    const gridStyle = {
      bg: 'white',
      width: '100%',
      height: '40px',
      p: '10px 0 10px 12px',
      fontSize: '12px',
      cursor: 'pointer',
    }
    const boxWithExternalLink = (path: string, text: string) => {
      if (isPreview) {
        return (
          <Flex alignItems="center" textAlign="center">
            <Text fontSize="12px" lineHeight="20px" mr="10px">
              {text}
            </Text>
            <ExternalLinkIcon color="gray.400" boxSize="16px" lineHeight="20px" />
          </Flex>
        )
      }
      return (
        <ChakraLink
          href={path}
          linkTo="outside"
          isExternal
          target="_blank"
          _hover={{ textDecoration: 'none' }}
        >
          <Flex alignItems="center" textAlign="center">
            <Text fontSize="12px" lineHeight="20px" mr="10px">
              {text}
            </Text>
            <ExternalLinkIcon color="gray.400" boxSize="16px" lineHeight="20px" />
          </Flex>
        </ChakraLink>
      )
    }
    return (
      <Flex
        as="footer"
        id="footer"
        bg="surface.primary"
        width="100%"
        maxWidth="640px"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        margin="0 auto"
        mt="1px"
      >
        <Flex h="160px" justifyContent="center" alignItems="center" bg="surface.primary">
          <Image src={Logo.src} alt="logo" width={100} height={40} />
        </Flex>
        <Box borderWidth="1px 0" borderStyle="solid" borderColor="gray.200" {...boxStyleWithIcon}>
          {isPreview ? (
            <Flex alignItems="center" justifyContent="space-between">
              <Flex gap={2.5} alignItems="center" textAlign="center">
                <HomeIcon category="footer" />
                <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                  Zキャリア ホーム
                </Text>
              </Flex>
              <ChevronRightIcon color="gray.400" boxSize="20px" />
            </Flex>
          ) : (
            <ChakraLink
              href={isLocal ? `${talentAppUrl}/` : '/'}
              linkTo="talent"
              _hover={{ textDecoration: 'none' }}
              data-gtm-click-item={'footer_link_top' as GTMClickItemName}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Flex gap={2.5} alignItems="center" textAlign="center">
                  <HomeIcon category="footer" />
                  <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                    Zキャリア ホーム
                  </Text>
                </Flex>
                <ChevronRightIcon color="gray.400" boxSize="20px" />
              </Flex>
            </ChakraLink>
          )}
        </Box>
        <Box borderBottom="1px solid #E2E8F0" {...boxStyleWithIcon}>
          {isPreview ? (
            <Flex alignItems="center" justifyContent="space-between">
              <Flex gap={2.5} alignItems="center" textAlign="center">
                <DocumentTextIcon category="footer" />
                <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                  すべての求人
                </Text>
              </Flex>
              <ChevronRightIcon color="gray.400" boxSize="20px" />
            </Flex>
          ) : (
            <ChakraLink
              href={isLocal ? `${talentAppUrl}/job` : '/job'}
              linkTo="talent"
              _hover={{ textDecoration: 'none' }}
              data-gtm-click-item={'footer_link_job' as GTMClickItemName}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Flex gap={2.5} alignItems="center" textAlign="center">
                  <DocumentTextIcon category="footer" />
                  <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                    すべての求人
                  </Text>
                </Flex>
                <ChevronRightIcon color="gray.400" boxSize="20px" />
              </Flex>
            </ChakraLink>
          )}
        </Box>
        <Box borderBottom="1px solid #E2E8F0" {...boxStyleWithIcon}>
          {isPreview ? (
            <Flex alignItems="center" justifyContent="space-between">
              <Flex gap={2.5} alignItems="center" textAlign="center">
                <Image src={Heart.src} alt="キープ" width={20} height={20} />
                <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                  キープした求人
                </Text>
              </Flex>
              <ChevronRightIcon color="gray.400" boxSize="20px" />
            </Flex>
          ) : (
            <ChakraLink
              href={isLocal ? `${talentAppUrl}/job/keep-list` : '/job/keep-list'}
              linkTo="talent"
              _hover={{ textDecoration: 'none' }}
              data-gtm-click-item={'footer_link_keep_list' as GTMClickItemName}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Flex gap={2.5} alignItems="center" textAlign="center">
                  <Image src={Heart.src} alt="キープ" width={20} height={20} />
                  <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                    キープした求人
                  </Text>
                </Flex>
                <ChevronRightIcon color="gray.400" boxSize="20px" />
              </Flex>
            </ChakraLink>
          )}
        </Box>

        {isPreview ? (
          <Flex w="100%" flexDirection="column">
            <Button
              h="48px"
              p="14px 16px 14px 12px"
              justifyContent="space-between"
              bg="white"
              borderBottom="1px solid #E2E8F0"
              _hover={{ bg: 'surface.primary' }}
            >
              <Flex gap={2.5} alignItems="center" textAlign="center">
                <LocationMarker category="footer" />
                <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                  勤務地から求人を探す
                </Text>
              </Flex>
              <ChevronDownIcon color="gray.400" boxSize="20px" />
            </Button>
            <Button
              h="48px"
              p="14px 16px 14px 12px"
              justifyContent="space-between"
              bg="white"
              borderBottom="1px solid #E2E8F0"
              _hover={{ bg: 'surface.primary' }}
            >
              <Flex gap={2.5} alignItems="center" textAlign="center">
                <BriefcaseIcon category="footer" />
                <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                  職種から求人を探す
                </Text>
              </Flex>
              <ChevronDownIcon color="gray.400" boxSize="20px" />
            </Button>
            <Button
              h="48px"
              p="14px 16px 14px 12px"
              justifyContent="space-between"
              bg="white"
              borderBottom="1px solid #E2E8F0"
              _hover={{ bg: 'surface.primary' }}
            >
              <Flex gap={2.5} alignItems="center" textAlign="center">
                <Image src={LightBulb.src} alt="こだわり条件" width={20} height={20} />
                <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                  こだわり条件から求人を探す
                </Text>
              </Flex>
              <ChevronDownIcon color="gray.400" boxSize="20px" />
            </Button>
            <Button
              w="100%"
              h="48px"
              p="14px 16px 14px 12px"
              justifyContent="space-between"
              bg="white"
              borderBottom="1px solid #E2E8F0"
              _hover={{ bg: 'surface.primary' }}
            >
              <Flex gap={2.5} alignItems="center" textAlign="center">
                <Image src={AcademicCap.src} alt="就職・転職ガイド" width={20} height={20} />
                <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                  就職・転職ガイド
                </Text>
              </Flex>
              <ChevronDownIcon color="gray.400" boxSize="20px" />
            </Button>
          </Flex>
        ) : (
          <FooterAccordionList
            jobCategories={jobCategories}
            isLocal={isLocal}
            talentAppUrl={talentAppUrl}
            guideAppUrl={guideAppUrl}
          />
        )}

        <SimpleGrid
          w="100%"
          bg="gray.200"
          borderBottom="1px solid #E2E8F0"
          columns={2}
          spacingX="1px"
          spacingY="1px"
        >
          <Box {...gridStyle}>{boxWithExternalLink(`${talentAppUrl}/docs/terms`, '利用規約')}</Box>
          <Box {...gridStyle}>{boxWithExternalLink(privacyPolicyPage, '個人情報保護方針')}</Box>
          {/* 初期段階では削除 */}
          {/* <Box {...gridStyle}>{boxWithExternalLink(externalContent, '外部コンテンツの利用')}</Box> */}
          <Box {...gridStyle}>{boxWithExternalLink(inquiryPage, 'お問い合わせ')}</Box>
          <Box {...gridStyle}>{boxWithExternalLink(operatingCompanyPage, '運営会社')}</Box>
        </SimpleGrid>
        <Box className={style.p_mark}>
          {/* リリース時点では非表示 */}
          {/* <Box bg="#EDF2F7" borderRadius="8px" p="12px" color="text.secondary" mb="40px">
            <Heading fontSize="12px" mb="8px">
              {`${defaultTitle}について`}
            </Heading>
            <Text fontSize="12px" lineHeight="18px">
              {`未経験層に向けた転職・求人サイト${defaultTitle}は株式会社ROXXが運営しています。${defaultTitle}は正社員求人を中心に掲載し、利用者は掲載された求人にサイト上から応募できます。地域、職種、こだわり条件から希望条件にマッチした求人検索が可能です。また、${defaultTitle}を通じて、専任のキャリアアドバイザーへ個別相談も申し込みいただけます。${defaultTitle}の就職・転職サービスは、学歴・職歴・スキルを問わず「${defaultTitle}層」の就職・転職をサポートし、今後の可能性を最大化します。`}
            </Text>
          </Box> */}
          <Center mb="40px">
            {/* guide側のSSGで参照できないためChakraUIのImageを利用する */}
            <ChakraImage width="80px" src={Pmark.src} alt="p-mark" />
          </Center>
          <Center>
            <Text fontSize="10px" color="gray.700">
              ©ROXX, Inc. ALL RIGHTS RESERVED.
            </Text>
          </Center>
        </Box>
      </Flex>
    )
  },
)

export default Footer
