interface Props {
  category: 'jobDetail' | 'footer' | 'search'
}

export const BriefcaseIcon: React.FC<Props> = (props: Props) => {
  const { category } = props
  // categoryがjobDetailの場合fillは"#4A5568"、categoryがsearchの場合fillは"#FFFFFF"
  const fill = category === 'jobDetail' ? '#4A5568' : '#FFFFFF'
  if (category === 'jobDetail' || category === 'search')
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8001 4.8001V4.0001C4.8001 2.67461 5.87461 1.6001 7.20009 1.6001H8.80009C10.1256 1.6001 11.2001 2.67461 11.2001 4.0001V4.8001H12.8001C13.6837 4.8001 14.4001 5.51644 14.4001 6.4001V9.25671C12.4078 9.99588 10.252 10.4001 8.00011 10.4001C5.74821 10.4001 3.59239 9.99588 1.6001 9.25669V6.4001C1.6001 5.51644 2.31644 4.8001 3.2001 4.8001H4.8001ZM6.40009 4.0001C6.40009 3.55827 6.75827 3.2001 7.20009 3.2001H8.80009C9.24192 3.2001 9.60009 3.55827 9.60009 4.0001V4.8001H6.40009V4.0001ZM7.20009 8.0001C7.20009 7.55827 7.55827 7.2001 8.00009 7.2001H8.00809C8.44992 7.2001 8.80809 7.55827 8.80809 8.0001C8.80809 8.44193 8.44992 8.8001 8.00809 8.8001H8.00009C7.55827 8.8001 7.20009 8.44193 7.20009 8.0001Z"
          fill={fill}
        />
        <path
          d="M1.6001 10.9539V12.8001C1.6001 13.6838 2.31644 14.4001 3.2001 14.4001H12.8001C13.6837 14.4001 14.4001 13.6838 14.4001 12.8001V10.9539C12.3899 11.6325 10.2372 12.0001 8.00011 12.0001C5.763 12.0001 3.61033 11.6324 1.6001 10.9539Z"
          fill={fill}
        />
      </svg>
    )
  else if (category === 'footer')
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 6V5C6 3.34315 7.34315 2 9 2H11C12.6569 2 14 3.34315 14 5V6H16C17.1046 6 18 6.89543 18 8V11.5708C15.5096 12.4947 12.8149 12.9999 10 12.9999C7.18514 12.9999 4.49037 12.4947 2 11.5707V8C2 6.89543 2.89543 6 4 6H6ZM8 5C8 4.44772 8.44772 4 9 4H11C11.5523 4 12 4.44772 12 5V6H8V5ZM9 10C9 9.44772 9.44772 9 10 9H10.01C10.5623 9 11.01 9.44772 11.01 10C11.01 10.5523 10.5623 11 10.01 11H10C9.44772 11 9 10.5523 9 10Z"
          fill="#1A202C"
        />
        <path
          d="M2 13.6923V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V13.6923C15.4872 14.5404 12.7964 14.9999 10 14.9999C7.20363 14.9999 4.51279 14.5404 2 13.6923Z"
          fill="#1A202C"
        />
      </svg>
    )
}
