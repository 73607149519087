interface Props {
  category: 'jobDetail' | 'footer' | 'search'
  color?: string
}

export const LocationMarker: React.FC<Props> = (props: Props) => {
  const { category, color } = props
  if (category === 'jobDetail' || category === 'search')
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9597 3.2403C14.1466 5.42724 14.1466 8.97296 11.9597 11.1599L7.9999 15.1197L4.0401 11.1599C1.85317 8.97296 1.85317 5.42724 4.0401 3.2403C6.22704 1.05336 9.77276 1.05336 11.9597 3.2403ZM7.9999 8.8001C8.88356 8.8001 9.5999 8.08375 9.5999 7.2001C9.5999 6.31644 8.88356 5.6001 7.9999 5.6001C7.11625 5.6001 6.3999 6.31644 6.3999 7.2001C6.3999 8.08375 7.11625 8.8001 7.9999 8.8001Z"
          fill={`${color || '#4A5568'}`}
        />
      </svg>
    )
  if (category === 'footer')
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.05025 4.05025C7.78392 1.31658 12.2161 1.31658 14.9497 4.05025C17.6834 6.78392 17.6834 11.2161 14.9497 13.9497L10 18.8995L5.05025 13.9497C2.31658 11.2161 2.31658 6.78392 5.05025 4.05025ZM10 11C11.1046 11 12 10.1046 12 9C12 7.89543 11.1046 7 10 7C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11Z"
          fill={`${color || '#1A202C'}`}
        />
      </svg>
    )
}
