import * as React from 'react'
import { ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import { useAtomValue } from 'jotai'
import Head from 'next/head'

import { addLineFriendUrl, appUrl, guideUrl } from '@/config'
import { authMeAtom } from '@/globalState/auth'
import { masterAtom } from '@/globalState/master'
import { useGlobalAlerts } from '@/hooks/talent/useGlobalAlerts'
import { useGlobalBadges } from '@/hooks/talent/useGlobalBadges'
import { useMaster } from '@/hooks/useMaster'
import styles from '@/layout/AppLayout.module.scss'
import Footer from '@/modules/components/Footer'
import Header from '@/modules/components/Header'
import { isLocal, isProd } from '@/modules/utils/env'

type Props = {
  children: ReactElement
  isPreview?: boolean
  showAgentConnectAppeal?: boolean
}
const AppLayout: React.FC<Props> = ({ children, isPreview, showAgentConnectAppeal = true }) => {
  // 認証状態のチェックをする
  const me = useAtomValue(authMeAtom)
  const masters = useAtomValue(masterAtom)
  const { showLineFriendNotice } = useGlobalAlerts()
  const { keepCount, unreadScoutCount } = useGlobalBadges()

  const jobCategories = masters.find((v) => v.category === 'job_category')?.items || []

  // 初期設定: masterを呼び出す
  useMaster([
    'job_category',
    'talent_employment_type',
    'job_required_employment_type',
    'talent_final_education',
    'job_required_final_education',
    'desired_job_start_period',
  ])

  return (
    <div>
      <Head>{isPreview && <meta name="robots" content="noindex" />}</Head>
      {!isProd && <div className={styles['under-construction']} />}
      <Header
        mode="talent"
        isPreview={isPreview}
        isLogin={!!me}
        talentAppUrl={appUrl}
        guideAppUrl={guideUrl}
        addLineFriendUrl={addLineFriendUrl}
        isLocal={isLocal}
        keepCount={keepCount ?? 0}
        unreadScoutCount={unreadScoutCount ?? 0}
        showLineFriendNotice={showLineFriendNotice ?? false}
      />
      <Box>{children}</Box>
      <Footer
        isPreview={isPreview}
        jobCategories={jobCategories}
        showAgentConnectAppeal={showAgentConnectAppeal}
        talentAppUrl={appUrl}
        guideAppUrl={guideUrl}
        isLocal={isLocal}
      />
    </div>
  )
}

export default AppLayout
