import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react'
import { particularConditions } from 'talent/const/particularConditions'
import { prefectures } from 'talent/const/prefectures'

import AcademicCap from '../assets/icon/AcademicCap.svg'
import { BriefcaseIcon } from '../assets/icon/BriefcaseIcon'
import LightBulb from '../assets/icon/LightBulb.svg'
import { LocationMarker } from '../assets/icon/LocationMarkerIcon'
import { GTMClickItemName } from '../utils/gtm/types/common'

import { ChakraLink } from './ChakraLink'

interface Props {
  jobCategories?: {
    id: number
    label: string
  }[]
  isLocal: boolean
  talentAppUrl?: string
  guideAppUrl?: string
}

export const FooterAccordionList: React.FC<Props> = (props: Props) => {
  const { jobCategories, isLocal, talentAppUrl, guideAppUrl } = props
  return (
    <Accordion allowMultiple width="100%" borderColor="gray.200">
      <AccordionItem
        borderTopWidth={0}
        data-gtm-click-item={'footer_accordion_prefecture' as GTMClickItemName}
      >
        <h3>
          <AccordionButton
            h="48px"
            p="14px 16px 14px 12px"
            justifyContent="space-between"
            _hover={{ bg: 'surface.primary' }}
          >
            <Flex gap={2.5} alignItems="center" textAlign="center">
              <LocationMarker category="footer" />
              <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                勤務地から求人を探す
              </Text>
            </Flex>
            <AccordionIcon color="gray.400" boxSize="20px" />
          </AccordionButton>
        </h3>
        <AccordionPanel paddingInlineStart={12} pb={4}>
          <Flex gap="4px 16px" flexWrap="wrap">
            {prefectures.map((v, i) => (
              <ChakraLink
                href={
                  isLocal
                    ? `${talentAppUrl}/job/workLocationPrefecture_${v.id}`
                    : `/job/workLocationPrefecture_${v.id}`
                }
                linkTo="talent"
                key={`pref-${i + 1}`}
                _hover={{ textDecoration: 'none' }}
                data-gtm-click-item={`footer_link_prefecture_${v.id}` as GTMClickItemName}
              >
                <Text fontSize="12px" lineHeight="20px">
                  {v.label}
                </Text>
              </ChakraLink>
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem data-gtm-click-item={'footer_accordion_job_type' as GTMClickItemName}>
        <h3>
          <AccordionButton
            h="48px"
            p="14px 16px 14px 12px"
            justifyContent="space-between"
            _hover={{ bg: 'surface.primary' }}
          >
            <Flex gap={2.5} alignItems="center" textAlign="center">
              <BriefcaseIcon category="footer" />
              <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                職種から求人を探す
              </Text>
            </Flex>
            <AccordionIcon color="gray.400" boxSize="20px" />
          </AccordionButton>
        </h3>
        <AccordionPanel paddingInlineStart={12} pb={4}>
          <Flex gap="4px 16px" flexWrap="wrap">
            {jobCategories?.map((v, i) => (
              <ChakraLink
                href={
                  isLocal ? `${talentAppUrl}/job/jobCategory_${v.id}` : `/job/jobCategory_${v.id}`
                }
                linkTo="talent"
                key={`category-${i + 1}`}
                _hover={{ textDecoration: 'none' }}
                data-gtm-click-item={`footer_link_job_type_${v.id}` as GTMClickItemName}
              >
                <Text fontSize="12px" lineHeight="20px">
                  {v.label}
                </Text>
              </ChakraLink>
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem data-gtm-click-item={'footer_accordion_appeal_point' as GTMClickItemName}>
        <h3>
          <AccordionButton
            h="48px"
            p="14px 16px 14px 12px"
            justifyContent="space-between"
            _hover={{ bg: 'surface.primary' }}
          >
            <Flex gap={2.5} alignItems="center" textAlign="center">
              <Image src={LightBulb.src} alt="こだわり条件" />
              <Text fontSize="14px" fontWeight={700} lineHeight="20px">
                こだわり条件から求人を探す
              </Text>
            </Flex>
            <AccordionIcon color="gray.400" boxSize="20px" />
          </AccordionButton>
        </h3>
        <AccordionPanel paddingInlineStart={12} pb={4}>
          <Flex gap="4px 16px" flexWrap="wrap">
            {particularConditions.map((v, i) => (
              <ChakraLink
                href={
                  isLocal
                    ? `${talentAppUrl}/job/particularCondition_${v.id}`
                    : `/job/particularCondition_${v.id}`
                }
                linkTo="talent"
                key={`condition-${i + 1}`}
                _hover={{ textDecoration: 'none' }}
                data-gtm-click-item={`footer_link_appeal_point_${v.id}` as GTMClickItemName}
              >
                <Text fontSize="12px" lineHeight="20px">
                  {v.label}
                </Text>
              </ChakraLink>
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>

      <ChakraLink
        href={isLocal ? `${guideAppUrl}/guide` : `/guide`}
        linkTo="talent"
        _hover={{ textDecoration: 'none' }}
        data-gtm-click-item={`footer_link_guide` as GTMClickItemName}
      >
        <Button
          w="100%"
          h="48px"
          p="14px 16px 14px 12px"
          justifyContent="space-between"
          bg="white"
          borderBottom="1px solid #E2E8F0"
          _hover={{ bg: 'surface.primary' }}
        >
          <Flex gap={2.5} alignItems="center" textAlign="center">
            <Image src={AcademicCap.src} alt="就職・転職ガイド" />
            <Text fontSize="14px" fontWeight={700} lineHeight="20px">
              就職・転職ガイド
            </Text>
          </Flex>
          <ChevronRightIcon color="gray.400" boxSize="20px" />
        </Button>
      </ChakraLink>
    </Accordion>
  )
}
